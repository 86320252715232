
import {defineComponent} from 'vue'
import {MDBBtn, MDBCol, MDBContainer, MDBRow} from "mdb-vue-ui-kit";

export default defineComponent({
  name: 'Home',
  components: {MDBBtn, MDBContainer, MDBRow, MDBCol},
  methods: {
    doLogin() {
      window.google.accounts.id.prompt()
    }
  }
});
